import React, { Fragment } from 'react'
import { graphql, StaticQuery} from 'gatsby'
import styled from 'react-emotion'
import ChapterDisplay from '../components/ChapterDisplay'
import Breadcrumb from '../components/common/Breadcrumb'
import Title from '../components/common/Title'
import Layout from "../layouts/index";

const ChapterList = styled.ul`
  flex-grow: 1;
  list-style-type: none;
  list-style-image: none;
  margin-left: 40px;
`

const ToCPage = props => (
  <Layout location={props.location}>
    {props.data &&
      <Fragment>
        <Breadcrumb to="/">Where the heck am I?</Breadcrumb>
        <Title>Table of Contents</Title>
        <ChapterList>
          {props.data.chapters
            ? props.data.chapters.edges.map(chapter => (
                <ChapterDisplay key={chapter.node.id} chapter={chapter.node} />
              ))
            : null}
        </ChapterList>
      </Fragment>
    }
  </Layout>
)

export default ToCPage

export const pageQuery = graphql`
  query ToCPageQuery {
    chapters: allContentfulChapter(
      sort: { fields: [chapterNumber], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          chapterNumber
          updatedAt
          page {
            id
            title
            pageNumber
            path
            updatedAt
          }
        }
      }
    }
  }
`
