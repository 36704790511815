import React, { PureComponent } from 'react'
import {Link} from 'gatsby'
import styled from 'react-emotion'
import tinycolor from 'tinycolor2'

const ChapterContainer = styled.li`
  font-family: 'PT Serif';
  & h2 {
    font-size: 28px;
    font-weight: normal;
    margin-bottom: 0.5rem;
    color: ${props => props.theme.red};
  }

  & button {
    border: 0 none;
    background-color: transparent;
    width: 100%;
    text-align: left;
    color: ${props => props.theme.red};

    & i {
      font-style: normal;
      color: ${props => props.theme.darkerYellow};
      margin-right: 0.5em;
      font-weight: bold;
    }

    &:hover {
      color: ${props =>
        tinycolor(props.theme.red)
          .darken()
          .toHexString()};
      & i {
        color: ${props =>
          tinycolor(props.theme.darkerYellow)
            .darken(15)
            .toHex8String()};
      }
    }

    &:focus {
      outline: none;
    }
  }
`

const PageListItem = styled.li`
  & a {
    font-size: 20px;
    text-decoration: none;
    color: ${props => props.theme.red};

    & i {
      font-style: normal;
      color: ${props => props.theme.darkerYellow};
      margin-right: 0.5em;
      font-weight: bold;
    }

    &:hover {
      color: ${props =>
        tinycolor(props.theme.red)
          .darken()
          .toHexString()};
      & i {
        color: ${props =>
          tinycolor(props.theme.darkerYellow)
            .darken(15)
            .toHex8String()};
      }
    }
  }
`

export default class ChapterDisplay extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    return (
      <ChapterContainer>
        <h2>
          <button onClick={this.handleOpen}>
            <i>#</i>
            {this.props.chapter.title}
          </button>
        </h2>
        {this.state.open ? (
          <ul
            css={{
              listStyleType: 'none',
              listStyleImage: 'none',
              marginLeft: 40,
            }}
          >
            {this.props.chapter.page && this.props.chapter.page
              .sort((a, b) => a.pageNumber - b.pageNumber)
              .map(page => (
                <PageListItem key={page.id}>
                  <Link to={`/${this.props.chapter.slug}/${page.path}`}>
                    <i>#</i>
                    {page.title}
                  </Link>
                </PageListItem>
              ))}
          </ul>
        ) : null}
      </ChapterContainer>
    )
  }
}
